import { CSSPropertiesDict } from "../../helpers/types";

const copyright: React.CSSProperties = {
  alignItems: "center",
  justifyContent: "center",
};

const footerStyle: CSSPropertiesDict = {
  copyright,
};

export default footerStyle;
