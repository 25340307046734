import { Grid2, Paper, useMediaQuery, useTheme } from "@mui/material";
import brand from "../../assets/pictures/logo/dark/brand.png";
import pic1 from "../../assets/pictures/illustrations/application.jpg";
import pic2 from "../../assets/pictures/illustrations/application2.jpg";
import pic3 from "../../assets/pictures/illustrations/application3.jpg";
import pic4 from "../../assets/pictures/illustrations/coding.jpg";
import pic5 from "../../assets/pictures/illustrations/coding2.jpg";
import pic6 from "../../assets/pictures/illustrations/multi-device.jpg";
import BaseContainer from "../../components/base/container";
import homePageStyle from "./style";
import HomePageContacts from "./footer";
import LogoSeparator from "../../components/shared/logo-separator";
import CodeIcon from "@mui/icons-material/Code";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import BuildIcon from "@mui/icons-material/Build";
import { darkTheme } from "../../helpers/theme";

const HomePage = () => {
  const theme = useTheme();
  const smallStyle = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className="HomePage"
      style={
        smallStyle ? homePageStyle.containerSmall : homePageStyle.container
      }
    >
      <BaseContainer>
        <img
          src={brand}
          alt="Northenware logo"
          style={smallStyle ? homePageStyle.brandSmall : homePageStyle.brand}
        />
        <Paper style={homePageStyle.paper}>
          <Grid2 container spacing={0}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <img
                src={pic2}
                alt="Multi-device responsive application"
                style={homePageStyle.illustration}
              />
            </Grid2>
            <Grid2 size={{ xs: 0, md: 6 }}>
              <img
                src={pic1}
                alt="Multi-device responsive application"
                style={homePageStyle.illustration}
              />
            </Grid2>

            <Grid2 size={12} sx={homePageStyle.descriptionGridItem}>
              <CodeIcon style={homePageStyle.descriptionIcon} />
            </Grid2>
            <Grid2 size={12}>
              <div className="description" style={homePageStyle.description}>
                We specialize in creating custom web applications that help
                businesses like yours streamline operations and improve customer
                engagement.
              </div>
            </Grid2>
          </Grid2>
        </Paper>

        <LogoSeparator />

        <Paper style={homePageStyle.paper}>
          <Grid2 container spacing={0}>
            <Grid2 size={{ xs: 0, md: 6 }}>
              <img
                src={pic4}
                alt="Multi-device responsive application"
                style={homePageStyle.illustration}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <img
                src={pic6}
                alt="Multi-device responsive application"
                style={homePageStyle.illustration}
              />
            </Grid2>

            <Grid2 size={12} sx={homePageStyle.descriptionGridItem}>
              <BuildIcon style={homePageStyle.descriptionIcon} />
            </Grid2>
            <Grid2 size={12}>
              <div className="description" style={homePageStyle.description}>
                Whether you need a website, an internal tool, or a custom
                platform, we build solutions that are fast, reliable, and
                tailored to your needs.
              </div>
            </Grid2>
          </Grid2>
        </Paper>

        <LogoSeparator />

        <Paper style={homePageStyle.paper}>
          <Grid2 container spacing={0}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <img
                src={pic3}
                alt="Multi-device responsive application"
                style={homePageStyle.illustration}
              />
            </Grid2>
            <Grid2 size={{ xs: 0, md: 6 }}>
              <img
                src={pic5}
                alt="Multi-device responsive application"
                style={homePageStyle.illustration}
              />
            </Grid2>

            <Grid2 size={12} sx={homePageStyle.descriptionGridItem}>
              <SettingsSuggestIcon style={homePageStyle.descriptionIcon} />
            </Grid2>
            <Grid2 size={12}>
              <div className="description" style={homePageStyle.description}>
                Using modern technologies, we ensure your web application is
                secure, scalable, and easy to maintain, so you can focus on
                growing your business.
              </div>
            </Grid2>
          </Grid2>
        </Paper>

        <HomePageContacts smallStyle={smallStyle} />
      </BaseContainer>
    </div>
  );
};

export default HomePage;
