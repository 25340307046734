import { Container } from "@mui/material";

const BaseContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="BaseContainer">
      <Container maxWidth="lg">{children}</Container>
    </div>
  );
};

export default BaseContainer;
