import { PaletteColorOptions, TypeBackground, TypeText } from "@mui/material";
import { NORD_COLORS } from "./colors";

export const primary: PaletteColorOptions = {
  main: NORD_COLORS.NORD10,
  contrastText: NORD_COLORS.NORD1,
};

export const text: Partial<TypeText> = {
  primary: NORD_COLORS.NORD6,
  // TODO: To update
  // secondary: NORD_COLORS.NORD6,
  // disabled: NORD_COLORS.NORD6,
};

export const background: TypeBackground = {
  default: NORD_COLORS.NORD0,
  paper: NORD_COLORS.NORD2,
};
