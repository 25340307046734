import CopyrightIcon from "@mui/icons-material/Copyright";
import { Stack } from "@mui/material";
import footerStyle from "./style";

const CREATION_YEAR = 2024;
const currentYear: number = new Date(Date.now()).getFullYear();

const copyRightYear: number | string =
  CREATION_YEAR == currentYear ? CREATION_YEAR : (
    `${CREATION_YEAR} - ${currentYear}`
  );

const BaseFooter = () => {
  return (
    <div className="BaseFooter">
      <Stack direction="row" spacing={1} sx={footerStyle.copyright}>
        <span>Northenware BV</span>
        <CopyrightIcon />
        <span>{copyRightYear}</span>
      </Stack>
    </div>
  );
};

export default BaseFooter;
