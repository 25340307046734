import "./fonts.css";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { darkTheme } from "./helpers/theme";
import HomePage from "./pages/home";
import BaseFooter from "./components/base/footer";
import BaseHeader from "./components/base/header";
import appStyle from "./style";

const App = () => {
  return (
    <div className="App" style={appStyle.defaultStyle}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline enableColorScheme />

        <header>
          <BaseHeader />
        </header>

        <body>
          <HomePage />
        </body>

        <footer>
          <BaseFooter />
        </footer>
      </ThemeProvider>
    </div>
  );
};

export default App;
