import logo from "../../assets/pictures/logo/dark/brand-logo.png";

const style: React.CSSProperties = {
  margin: "3px 0",
  width: "70px",
};

const LogoSeparator = () => {
  return (
    <div className="LogoSeparator">
      <img src={logo} alt="Northenware logo separator" style={style} />
    </div>
  );
};

export default LogoSeparator;
