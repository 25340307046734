export const NORD_COLORS = {
  NORD0: "#2e3440",
  NORD1: "#3b4252",
  NORD2: "#434c5e",
  NORD3: "#4c566a",
  NORD4: "#d8dee9",
  NORD5: "#e5e9f0",
  NORD6: "#eceff4",
  NORD7: "#8fbcbb",
  NORD8: "#88c0d0",
  NORD9: "#81a1c1",
  NORD10: "#5e81ac",
  NORD11: "#bf616a",
  NORD12: "#d08770",
  NORD13: "#ebcb8b",
  NORD14: "#a3be8c",
  NORD15: "#b48ead",
};
