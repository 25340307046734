import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Grid2, Stack, Tooltip } from "@mui/material";
import logo from "../../assets/pictures/logo/dark/brand-logo.png";
import { CONTACT_MAIL, LINKED_IN_LINK } from "../../helpers/consts";
import homePageStyle from "./style";
import React from "react";

const HomePageContacts: React.FC<{ smallStyle: boolean }> = ({
  smallStyle,
}) => {
  return (
    <div className="HomePageContacts">
      <Grid2 container spacing={smallStyle ? 0 : 5}>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <img
            src={logo}
            alt="Northenware Logo"
            style={homePageStyle.footerLogo}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 9 }}>
          <h2
            style={
              smallStyle ?
                homePageStyle.footerTitleSmall
              : homePageStyle.footerTitle
            }
          >
            Contact
          </h2>
          <p style={homePageStyle.footerDetails}>
            Northenware BV
            <br />
            {/* <span style={homePageStyle.vatNumber}>VAT 0000.000.000</span>
            <Stack direction="row" spacing={1}>
              <EmailIcon />
              <a href={"mailto:" + CONTACT_MAIL} style={homePageStyle.link}>
                {CONTACT_MAIL}
              </a>
            </Stack> */}
            <Stack direction="row" spacing={1}>
              <LinkedInIcon />
              <Tooltip title="Under construction" placement="right" arrow>
                <a href={LINKED_IN_LINK} style={homePageStyle.link}>
                  linkedIn.com/Northenware
                </a>
              </Tooltip>
            </Stack>
            <br />
            VAT number and contact mail address available soon.
            <br />
            Contact us through LinkedIn until then.
          </p>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default HomePageContacts;
