import fonts from "./helpers/fonts";
import { CSSPropertiesDict } from "./helpers/types";

const defaultStyle: React.CSSProperties = {
  fontFamily: fonts.default,
};

const appStyle: CSSPropertiesDict = {
  defaultStyle,
};

export default appStyle;
